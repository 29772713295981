import React, { Component } from 'react';
import classNames from 'classnames';

import { EventBus as eventBus } from "../EventBus";

class Footer extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            company: {
                name: '',
                url: '',
            },
            className: '',
        };
    }
    
    componentWillMount() {
        eventBus.$on("footer", (data) =>
            this.setState({ ...data })
        );
    }

    componentWillUnmount() {
        eventBus.$off("footer");
    }

    render () {
        const { company, className } = this.state;
        return (
            <div className={classNames({
                'container-fluid': true,
                [className]: true,
            })}>
                <p className="text-muted">Powered by <a className="poweredby" href={ company.url }>{ company.name }</a></p>
            </div>
        );
    }
}

export default Footer;