import React, { Component } from "react";
import { withCookies } from "react-cookie";
import axios from "axios";
import { NotificationManager } from "react-notifications";

// Components
import Input from "@components/Input";
import TextArea from "@components/TextArea";
import Checkbox from "@components/CheckBox";
import AutoComplete from "@components/AutoComplete";

import UserContext from "@components/context/userContext";

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    const codigo = this.props.codigo;

    this.state = {
      name: "",
      email: "",
      nif: "",
      phone: "",
      address: "",
      birthday: "",
      idassociado: "",
      credito_concedido: 0.00,
      quota: {
        value: 0,
        label: "Nenhuma",
      },
      healthSystem: '',
    number_ss: '',
    numero_utente: '',
    healthSystems: [],
      gdpr: false,
      quotaTypes: [
        {
          value: 0,
          label: "Nenhuma",
        },
        {
          value: 3,
          label: "Mensal",
        },
        {
          value: 2,
          label: "Trimestral",
        },
        {
          value: 5,
          label: "Quadrimestral",
        },
        {
          value: 4,
          label: "Semestral",
        },
        {
          value: 1,
          label: "Anual",
        },
      ],
      edit: codigo,
      loaded: false,
    };

    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  loadFunction() {
    const profile = this.context;
    const { codigo } = this.props;

    if (!this.state.loaded) {
        axios
            .get(
            `https://eposgestofinal.pt/api/health_systems/get.php`
            )
            .then((response) => {
                const healthSystems = response.data !== "NOK" ? response.data : [];
                this.setState({
                    healthSystems: [
                        {
                            label: 'Nenhum',
                            value: undefined,
                        },
                        ...healthSystems.map((healthSystem) => ({
                            ...healthSystem,
                            label: healthSystem.name,
                            value: healthSystem.idhealthsystem,
                        }))
                    ],
                    loaded: true,
                });
            });
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    });
  }

  handleCheckboxChange(e, callback) {
    let value = e.target.checked;
    let name = e.target.name;

    this.setState({
      [name]: value,
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const profile = this.context;
    const { history } = this.props;
    const { codigo } = this.props;
    const { edit } = this.state;

    axios
      .post("https://eposgestofinal.pt/api/clientes/set.php", {
        idempresa: profile.idEmpresa,
        nome_1: this.state.nome_1,
        nome_2: this.state.nome_2,
        nif: this.state.nif,
        email: this.state.email,
        telefone: this.state.telefone,
        telefone_2: this.state.telefone_2,
        fax: this.state.fax,
        telemovel: this.state.telemovel,
        morada_1: this.state.morada_1,
        morada_2: this.state.morada_2,
        codigo_postal: this.state.codigo_postal,
        credito_concedido: this.state.credito_concedido,
        ativo: 't',
      })
      .then((response) => {
        NotificationManager.success(
          "Cliente submetido com sucesso!",
          "Cliente"
        );
        eventBus.$emit("Modal-newcustomer", { isOpen: false });
        window.location.href = "/clientes";
      })
      .catch((error) => {
        NotificationManager.error(error.message, "Cliente");
      });
  }

  render() {
    const {
      nome_1,
      nome_2,
      nif,
      email,
      telefone,
      telefone_2,
      fax,
      telemovel,
      morada_1,
      morada_2,
      codigo_postal,
      credito_concedido,
      edit,
    } = this.state;
    this.loadFunction();

    return (
    <form class="well form-horizontal" action=" " method="post" id="contact_form">
        <div class="st st-theme-github st-horizontal">
            <fieldset>
                <div className="form-group">
                <label className="col-md-3 control-label">Nome 1</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-user"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"nome_1"}
                        value={nome_1}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Nome 2</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-user"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"nome_2"}
                        value={nome_2}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">NIF</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-id-card"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"nif"}
                        value={nif}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">E-Mail</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-envelope"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"email"}
                        value={email}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Telefone</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-phone"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"telefone"}
                        value={telefone}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Telefone 2</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-phone"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"telefone_2"}
                        value={telefone_2}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Fax</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-phone"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"fax"}
                        value={fax}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Telemovel</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-phone"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"telemovel"}
                        value={telemovel}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Morada 1</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-home"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"morada_1"}
                        value={morada_1}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Morada 2</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-home"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"morada_2"}
                        value={morada_2}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Código Postal</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-home"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"codigo_postal"}
                        value={codigo_postal}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Crédito Concebido</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-euro-sign"></i>
                    </span>
                    <Input
                        inputType={"number"}
                        step="0.01"
                        name={"credito_concedido"}
                        value={credito_concedido}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <div className="col-xs-12">
                    <button
                    type="button"
                    onClick={this.handleFormSubmit}
                    style={{ width: "100%" }}
                    className="btn btn-success"
                    >
                    {edit ? "Guardar" : "Criar"}
                    </button>
                </div>
                </div>
            </fieldset>
        </div>
    </form>
    );
  }
}

export default withCookies(Page);
