import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { withCookies, Cookies } from 'react-cookie';
import { TimestampToDate } from "@utils/date";

// Components
import Print from './print'
import ReactTable from '@components/ReactTable';
import Menu from '@components/Menu'
import Input from "@components/Input";
import AutoComplete from "@components/AutoComplete";

import { currentDateTime, dateToTimestamp } from '@utils/date'
import { twoDecimal } from '@utils/format.js'

import 'react-smarttab/dist/index.css'

import UserContext from '@components/context/userContext';

class Page extends Component {
    static contextType = UserContext;

    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        const today = new Date();

        this.state = {
            stocks: {
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'idproduct',
                    },
                    {
                      Header: 'Designação',
                      accessor: 'nome',
                    },
                    {
                      Header: 'Quantidade',
                      accessor: 'stock',
                    },
                    {
                        Header: 'Último Inventário',
                        accessor: 'inventory',
                      },
                    {
                      Header: 'Preço Custo',
                      accessor: 'costprice',
                    },
                    {
                      Header: 'V. Stock (€)',
                      accessor: 'total',
                    },
                ],
                rows: [],
                loading: false,
            },
            date: currentDateTime(),
            categories: [],
            category: JSON.parse(localStorage.getItem("stocks.category")) || {
                value: "",
                label: "TODAS",
            },
            products: [],
            product: JSON.parse(localStorage.getItem("stocks.product")) || {
                value: "",
                label: "TODOS",
            },
            postos: [],
            posto: JSON.parse(localStorage.getItem("stocks.posto")) || {
                value: "",
                label: "TODOS",
            },
            loaded: false,
        };

        this.loadFunction = this.loadFunction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getStocks = this.getStocks.bind(this);
    }

    getStocks() {
      const profile = this.context;
      const { date, posto, category, product } = this.state;
      
      this.setState({ stocks: { ...this.state.stocks, rows: [], loading: true } });

      let familia = '';
      if (category.value !== '') {
        familia = `&familia=${category.value}`;
      }

      let idproduct = '';
      if (product.value !== '') {
        idproduct = `&idproduct=${product.value}`;
      }

      let idposto = '';
      if (posto.value !== '') {
        idposto = `&idposto=${posto.value}`;
      }

      axios.get(`https://eposgestofinal.pt/api/postos/stocks/getById.php?idempresa=${profile.idEmpresa}&date=${dateToTimestamp(date)}${idposto}${familia}${idproduct}`)
        .then(response => {
            const stocks = response.data !== 'NOK' ? response.data : [];

            this.setState({ stocks: { ...this.state.stocks, rows: stocks.map((eachStock) => ({ ...eachStock , inventory: eachStock.inventory ? TimestampToDate(eachStock.inventory) : '', total: eachStock.costprice && eachStock.stock && twoDecimal(parseFloat(eachStock.costprice) * parseFloat(eachStock.stock)) })), loading: false } });
        })
        .catch(error => {
            NotificationManager.error(error.message, 'Senhas');
        });
    }

    async handleChange(e, callback) {
      let value = e.target.value;
      let name = e.target.name;

      await this.setState({
        [name]: value,
      });

      const { posto, category, product } = this.state;

      switch (name) {
        case "posto":
        case "category":
        case "product":
            localStorage.setItem(`stocks.${name}`, JSON.stringify(value));
            break;
        default:
            break;
        }

      if (['posto', 'category', 'date', 'product'].includes(name)) {
        if ((category !== '' || product !== '') && posto !== '') {
          this.getStocks();
        }
      }
    }

    loadFunction() {
        const profile = this.context;

        if (!this.state.loaded) {
          axios.get(`https://eposgestofinal.pt/api/categories/get.php?idempresa=${profile.idEmpresa}`)
              .then(response => {
                  const categories = response.data !== 'NOK' ? response.data : [];

                  const enrichedCategories = categories.map((category) => ({ value: category.idcategoria, label: category.descricaocategoria }))

                  this.setState({ categories: [{ value: '', label: 'TODAS' },...enrichedCategories], loaded: true });
                  axios.get(`https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresa}`)
                      .then(response => {
                          const postos = response.data !== 'NOK' ? response.data : [];
        
                          const enrichedPostos = postos.map((posto) => ({ value: posto.posto, label: posto.nome }));
        
                          this.setState({ postos: [{ value: '', label: 'TODOS' },...enrichedPostos], loaded: true });
                          axios.get(`https://eposgestofinal.pt/api/products/get.php?idempresa=${profile.idEmpresa}&mov_stock=true`)
                              .then(response => {
                                  const products = response.data !== 'NOK' ? response.data : [];
                      
                                  this.setState({ products: [{ value: '', label: 'TODOS' }, ...products.map((product) => ({ ...product, value: product.idproduct, label: product.nome }))], loaded: true }, () => {
                                    this.getStocks();
                                  });
                              })
                              .catch(error => {
                                  console.error(error);
                              });
                      })
                      .catch(error => {
                          NotificationManager.error(error.message, 'Postos');
                      });
              })
              .catch(error => {
                  NotificationManager.error(error.message, 'Categorias');
              });
        }
    }

    render () {
        const { location, history } = this.props;
        const { stocks, categories, products, date, postos, posto, category, product } = this.state;
        const cookies = new URLSearchParams(location.search);

        return (
          <div>
            <Menu
              loadFunction={this.loadFunction()}
              location={location}
              history={history}
              newCookies={cookies}
            />
            <div className="fullContainer" style={{ maxWidth: "100%" }}>
              <div className="col-xs-12">
                <ol
                  style={{
                    textAlign: "left",
                    marginTop: "75px",
                  }}
                  className="breadcrumb"
                >
                  <li>
                    <a href="http://eposgestofinal.pt/index.php">Portal</a>
                  </li>
                  <li className="active">Stocks</li>
                </ol>
              </div>
            </div>
            <div
                className="fullContainer"
                style={{ marginTop:'25px' }}
            >
                <div
                    className="col-sm-12"
                    style={{ textAlign: 'center', fontSize: '10px' }}
                >
                    <div className="panel panel-default">
                        <div
                            className="panel-heading"
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15pt',
                            }}
                        >
                            Stocks
                        </div>
                      <h3>Filtros</h3>
                      <table className="table table-bordered table-hover table-sortable">
                          <thead>
                              <tr >
                                  <th className="text-center">Data</th>
                                  <th className="text-center">Posto</th>
                                  <th className="text-center">Familia</th>
                                  <th className="text-center">Produto</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr >
                                  <td style={{ width: '250px' }}>
                                  <Input
                                      inputType={"datetime-local"}
                                      name={"date"}
                                      value={date}
                                      handleChange={this.handleChange}
                                  />
                                  </td>
                                  <td style={{ width: '250px' }}>
                                      <AutoComplete
                                          name={"posto"}
                                          value={posto}
                                          handleChange={this.handleChange}
                                          options={postos}
                                      />
                                  </td>
                                  <td style={{ width: '250px' }}>
                                      <AutoComplete
                                          name={"category"}
                                          value={category}
                                          handleChange={this.handleChange}
                                          options={categories}
                                      />
                                  </td>
                                  <td>
                                      <AutoComplete
                                          name={"product"}
                                          value={product}
                                          handleChange={this.handleChange}
                                          options={products}
                                      />
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      <table style={{ marginBottom: 0 }} className="table table-bordered-only-inside">
                          <tbody>
                              <tr>
                                  <td style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }}>
                                      {stocks.rows.length > 0 ? (
                                          <Print
                                              table={ stocks }
                                              date={ date }
                                              posto={ posto }
                                              category={ category }
                                          />
                                      ) : null}
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      <ReactTable
                          columns={ stocks.columns }
                          sortable={ true }
                          loading={ stocks.loading }
                          data={ stocks.rows }
                      />
                  </div>
                </div>
            </div>
          </div>
        );
    }
}

export default withCookies(Page);